import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

import ContactIcon from "../assets/contact.svg"
import MailIcon from "../assets/mail.svg"
import WebsiteIcon from "../assets/website.svg"
import InstagramIcon from "../assets/instagram.svg"
import FacebookIcon from "../assets/facebook.svg"
import TwitterIcon from "../assets/twitter.svg"
import BehanceIcon from "../assets/behance.svg"
import LinkedInIcon from "../assets/linkedin.svg"

const CardPage = () => (
    <>
        <style>
        {`body {
            background: black;
            color: white;
        }
        div.profile {
            border-radius: 100%;
            -webkit-border-radius: 100%;
        }
        div.profile img {
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }
        `}
        </style>
        <Seo title="Card" />
        <main className="">
            <div className="w-px120 h-px120 mx-auto mt-28 overflow-clip profile">
                <StaticImage
                    src="../images/photos/profile.jpg"
                    alt="Profile picture"
                    placeholder="blurred"
                    layout="fixed"
                    width={120}
                    height={120}                
                />
            </div>
            
            <div className="mx-auto w-full text-center mt-14 mb-28">
                Hallo ich bin Bardhi, <br/>
                du bist nice!
            </div>
            <div className="mx-auto w-px300">
                <div className="flex justify-between flex-wrap my-14 mx-8">
                    <a href="/vcard.vcf" className="w-px40 h-px40 flex justify-center items-center">
                        <ContactIcon />
                    </a>
                    <a href="https://sokoli.co" className="w-px40 h-px40 flex justify-center items-center">
                        <WebsiteIcon />
                    </a>
                    <a href="https://behance.net/sokoli" className="w-px40 h-px40 flex justify-center items-center">
                        <BehanceIcon />
                    </a>
                </div>
                <div className="flex justify-between flex-wrap my-14 mx-8">
                    <a href="https://instagram.com/bardhsokoli" className="w-px40 h-px40 flex justify-center items-center">
                        <InstagramIcon />
                    </a>
                    <a href="https://www.facebook.com/bardh2" className="w-px40 h-px40 flex justify-center items-center">
                        <FacebookIcon />
                    </a>
                    <a href="https://www.linkedin.com/in/bardh-sokoli-4b6002205/" className="w-px40 h-px40 flex justify-center items-center">
                        <LinkedInIcon />
                    </a>
                </div>
            </div>
        </main>
    </>
);


export default CardPage;